import { default as forgot_45passwordd41MVLIjg6Meta } from "/workspace/pages/auth/forgot-password.vue?macro=true";
import { default as loginipm5N7UCSPMeta } from "/workspace/pages/auth/login.vue?macro=true";
import { default as registerpasi0PSgLSMeta } from "/workspace/pages/auth/register.vue?macro=true";
import { default as reset_45passwordCuimr8exfGMeta } from "/workspace/pages/auth/reset-password.vue?macro=true";
import { default as verifiedTiKYllrhf0Meta } from "/workspace/pages/auth/verified.vue?macro=true";
import { default as verify_45emailabnReZy0RuMeta } from "/workspace/pages/auth/verify-email.vue?macro=true";
import { default as _91id_93V6z6dzGFWpMeta } from "/workspace/pages/blog/[slug]/[id].vue?macro=true";
import { default as indexRgrKfU8NaHMeta } from "/workspace/pages/blog/index.vue?macro=true";
import { default as ccpa6i0DzmluHMMeta } from "/workspace/pages/ccpa.vue?macro=true";
import { default as _91id_93g4lnbnh7sKMeta } from "/workspace/pages/destinations/[id].vue?macro=true";
import { default as createYX5Lb0Enz9Meta } from "/workspace/pages/destinations/[trip_id]/create.vue?macro=true";
import { default as edit_45_91id_93HfLXpQ1fE9Meta } from "/workspace/pages/destinations/edit-[id].vue?macro=true";
import { default as createf0K1Z2DG48Meta } from "/workspace/pages/destinations/itineraries-[destination_id]/create.vue?macro=true";
import { default as _91id_93MRodBWYRbeMeta } from "/workspace/pages/gear/[id].vue?macro=true";
import { default as createNzZkf1aKhRMeta } from "/workspace/pages/gear/create.vue?macro=true";
import { default as edit_45_91id_93lMHdw3sxoxMeta } from "/workspace/pages/gear/edit-[id].vue?macro=true";
import { default as index9smsnmwC7WMeta } from "/workspace/pages/gear/index.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as _91invite_id_938wWSmtzGXkMeta } from "/workspace/pages/invites/[invite_id].vue?macro=true";
import { default as _91id_937COde1Q90pMeta } from "/workspace/pages/itineraries/[id].vue?macro=true";
import { default as _91slug_93RN9hT8m9HLMeta } from "/workspace/pages/locations/[slug].vue?macro=true";
import { default as _91id_93iCM7WIojllMeta } from "/workspace/pages/manufacturers/[id].vue?macro=true";
import { default as create3oGAB9VCH4Meta } from "/workspace/pages/manufacturers/create.vue?macro=true";
import { default as edit_45_91id_93Lm4EY8PAaQMeta } from "/workspace/pages/manufacturers/edit-[id].vue?macro=true";
import { default as indexSBMr6bouvfMeta } from "/workspace/pages/manufacturers/index.vue?macro=true";
import { default as maplckGjiLVPMMeta } from "/workspace/pages/map.vue?macro=true";
import { default as _91slug_93t9h2Itc2V0Meta } from "/workspace/pages/pages/[slug].vue?macro=true";
import { default as privacy_45policyJuUuuPjb3dMeta } from "/workspace/pages/privacy-policy.vue?macro=true";
import { default as _91campsite_93ApiTCOzz2cMeta } from "/workspace/pages/rivers/[river_slug]/sections/[section_slug]/campsites/[campsite].vue?macro=true";
import { default as _91section_93W0Eg57r7unMeta } from "/workspace/pages/rivers/[river_slug]/sections/[section].vue?macro=true";
import { default as _91river_93o58BM4fmiZMeta } from "/workspace/pages/rivers/[river].vue?macro=true";
import { default as index5gndrhUcyGMeta } from "/workspace/pages/rivers/index.vue?macro=true";
import { default as terms_45of_45serviceHYXJhzWwTAMeta } from "/workspace/pages/terms-of-service.vue?macro=true";
import { default as _91id_93aCVdOxPC8WMeta } from "/workspace/pages/trips/[id].vue?macro=true";
import { default as mapFLHltSmZDfMeta } from "/workspace/pages/trips/[trip_id]/map.vue?macro=true";
import { default as createeHEfCQ2rpTMeta } from "/workspace/pages/trips/create.vue?macro=true";
import { default as indexTKfxHO0W9eMeta } from "/workspace/pages/trips/index.vue?macro=true";
import { default as dashboardc9hn86NuHTMeta } from "/workspace/pages/user/dashboard.vue?macro=true";
import { default as profileGiTF2KAJONMeta } from "/workspace/pages/user/profile.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordd41MVLIjg6Meta || {},
    component: () => import("/workspace/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginipm5N7UCSPMeta || {},
    component: () => import("/workspace/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerpasi0PSgLSMeta || {},
    component: () => import("/workspace/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordCuimr8exfGMeta || {},
    component: () => import("/workspace/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-verified",
    path: "/auth/verified",
    meta: verifiedTiKYllrhf0Meta || {},
    component: () => import("/workspace/pages/auth/verified.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    meta: verify_45emailabnReZy0RuMeta || {},
    component: () => import("/workspace/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug-id",
    path: "/blog/:slug()/:id()",
    component: () => import("/workspace/pages/blog/[slug]/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/workspace/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "ccpa",
    path: "/ccpa",
    component: () => import("/workspace/pages/ccpa.vue").then(m => m.default || m)
  },
  {
    name: "destinations-id",
    path: "/destinations/:id()",
    meta: _91id_93g4lnbnh7sKMeta || {},
    component: () => import("/workspace/pages/destinations/[id].vue").then(m => m.default || m)
  },
  {
    name: "destinations-trip_id-create",
    path: "/destinations/:trip_id()/create",
    meta: createYX5Lb0Enz9Meta || {},
    component: () => import("/workspace/pages/destinations/[trip_id]/create.vue").then(m => m.default || m)
  },
  {
    name: "destinations-edit-id",
    path: "/destinations/edit-:id()",
    meta: edit_45_91id_93HfLXpQ1fE9Meta || {},
    component: () => import("/workspace/pages/destinations/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: "destinations-itineraries-destination_id-create",
    path: "/destinations/itineraries-:destination_id()/create",
    meta: createf0K1Z2DG48Meta || {},
    component: () => import("/workspace/pages/destinations/itineraries-[destination_id]/create.vue").then(m => m.default || m)
  },
  {
    name: "gear-id",
    path: "/gear/:id()",
    meta: _91id_93MRodBWYRbeMeta || {},
    component: () => import("/workspace/pages/gear/[id].vue").then(m => m.default || m)
  },
  {
    name: "gear-create",
    path: "/gear/create",
    meta: createNzZkf1aKhRMeta || {},
    component: () => import("/workspace/pages/gear/create.vue").then(m => m.default || m)
  },
  {
    name: "gear-edit-id",
    path: "/gear/edit-:id()",
    meta: edit_45_91id_93lMHdw3sxoxMeta || {},
    component: () => import("/workspace/pages/gear/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: "gear",
    path: "/gear",
    meta: index9smsnmwC7WMeta || {},
    component: () => import("/workspace/pages/gear/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invites-invite_id",
    path: "/invites/:invite_id()",
    meta: _91invite_id_938wWSmtzGXkMeta || {},
    component: () => import("/workspace/pages/invites/[invite_id].vue").then(m => m.default || m)
  },
  {
    name: "itineraries-id",
    path: "/itineraries/:id()",
    meta: _91id_937COde1Q90pMeta || {},
    component: () => import("/workspace/pages/itineraries/[id].vue").then(m => m.default || m)
  },
  {
    name: "locations-slug",
    path: "/locations/:slug()",
    meta: _91slug_93RN9hT8m9HLMeta || {},
    component: () => import("/workspace/pages/locations/[slug].vue").then(m => m.default || m)
  },
  {
    name: "manufacturers-id",
    path: "/manufacturers/:id()",
    meta: _91id_93iCM7WIojllMeta || {},
    component: () => import("/workspace/pages/manufacturers/[id].vue").then(m => m.default || m)
  },
  {
    name: "manufacturers-create",
    path: "/manufacturers/create",
    meta: create3oGAB9VCH4Meta || {},
    component: () => import("/workspace/pages/manufacturers/create.vue").then(m => m.default || m)
  },
  {
    name: "manufacturers-edit-id",
    path: "/manufacturers/edit-:id()",
    meta: edit_45_91id_93Lm4EY8PAaQMeta || {},
    component: () => import("/workspace/pages/manufacturers/edit-[id].vue").then(m => m.default || m)
  },
  {
    name: "manufacturers",
    path: "/manufacturers",
    meta: indexSBMr6bouvfMeta || {},
    component: () => import("/workspace/pages/manufacturers/index.vue").then(m => m.default || m)
  },
  {
    name: "map",
    path: "/map",
    meta: maplckGjiLVPMMeta || {},
    component: () => import("/workspace/pages/map.vue").then(m => m.default || m)
  },
  {
    name: "pages-slug",
    path: "/pages/:slug()",
    component: () => import("/workspace/pages/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/workspace/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "rivers-river_slug-sections-section_slug-campsites-campsite",
    path: "/rivers/:river_slug()/sections/:section_slug()/campsites/:campsite()",
    meta: _91campsite_93ApiTCOzz2cMeta || {},
    component: () => import("/workspace/pages/rivers/[river_slug]/sections/[section_slug]/campsites/[campsite].vue").then(m => m.default || m)
  },
  {
    name: "rivers-river_slug-sections-section",
    path: "/rivers/:river_slug()/sections/:section()",
    component: () => import("/workspace/pages/rivers/[river_slug]/sections/[section].vue").then(m => m.default || m)
  },
  {
    name: "rivers-river",
    path: "/rivers/:river()",
    component: () => import("/workspace/pages/rivers/[river].vue").then(m => m.default || m)
  },
  {
    name: "rivers",
    path: "/rivers",
    component: () => import("/workspace/pages/rivers/index.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/workspace/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: "trips-id",
    path: "/trips/:id()",
    meta: _91id_93aCVdOxPC8WMeta || {},
    component: () => import("/workspace/pages/trips/[id].vue").then(m => m.default || m)
  },
  {
    name: "trips-trip_id-map",
    path: "/trips/:trip_id()/map",
    meta: mapFLHltSmZDfMeta || {},
    component: () => import("/workspace/pages/trips/[trip_id]/map.vue").then(m => m.default || m)
  },
  {
    name: "trips-create",
    path: "/trips/create",
    meta: createeHEfCQ2rpTMeta || {},
    component: () => import("/workspace/pages/trips/create.vue").then(m => m.default || m)
  },
  {
    name: "trips",
    path: "/trips",
    meta: indexTKfxHO0W9eMeta || {},
    component: () => import("/workspace/pages/trips/index.vue").then(m => m.default || m)
  },
  {
    name: "user-dashboard",
    path: "/user/dashboard",
    meta: dashboardc9hn86NuHTMeta || {},
    component: () => import("/workspace/pages/user/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileGiTF2KAJONMeta || {},
    component: () => import("/workspace/pages/user/profile.vue").then(m => m.default || m)
  }
]