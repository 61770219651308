import { config, library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAddressBook,
  faAlarmClock,
  faArrowLeft,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowsRotate,
  faArrowsSpin,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faBan,
  faBars,
  faBell,
  faBellPlus,
  faBellSlash,
  faBenchTree,
  faBlog,
  faBold,
  faBookmark,
  faBookOpenCover,
  faCalendar,
  faCalendarCircleExclamation,
  faCalendarLinesPen,
  faCampground,
  faCaravan,
  faCaretLeft,
  faCaretRight,
  faChartNetwork,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faCircleUser,
  faClipboardListCheck,
  faClone,
  faCloudBoltSun,
  faCommentPlus,
  faComments,
  faCompass,
  faCreditCard,
  faCrosshairs,
  faDiamondTurnRight,
  faDown,
  faDownload,
  faEdit,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
  faEye,
  faFileContract,
  faFileExport,
  faFileImport,
  faFileMagnifyingGlass,
  faFilePdf,
  faGarage,
  faGauge,
  faGear,
  faGears,
  faGrip,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faHouse,
  faHouseTree,
  faImage,
  faImageLandscape,
  faIndustry,
  faInfo,
  faItalic,
  faList,
  faListCheck,
  faListOl,
  faListUl,
  faLocationCheck,
  faLocationCrosshairs,
  faLocationDot,
  faLocationPlus,
  faLock,
  faMagnifyingGlass,
  faMagnifyingGlassLocation,
  faMap,
  faMapLocationDot,
  faMessageLines,
  faMountainCity,
  faPaperclip,
  faPaperPlaneTop,
  faPencil,
  faPersonCarryBox,
  faPersonCircleMinus,
  faPersonHiking,
  faPhone,
  faPhotoFilm,
  faPlay,
  faPlus,
  faRoute,
  faRulerTriangle,
  faRv,
  faSave,
  faShieldKeyhole,
  faSnowflake,
  faSort,
  faSquarePlus,
  faSquareXmark,
  faStar,
  faStopwatch,
  faTint,
  faToolbox,
  faTrashCan,
  faTrees,
  faTriangleExclamation,
  faUp,
  faUpload,
  faUser,
  faUserCheck,
  faUserLock,
  faUserPlus,
  faUsers,
  faWandSparkles,
  faWater,
  faXmark,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  // @ts-ignore
  faEdit,
  faUser,
  faSquareXmark,
  faCheck,
  faPlus,
  faTriangleExclamation,
  faXmark,
  faRv,
  faCalendar,
  faUsers,
  faSave,
  faBan,
  faImageLandscape,
  faLocationDot,
  faGripLines,
  faArrowRightFromBracket,
  faCircleUser,
  faBars,
  faMap,
  faGauge,
  faPaperclip,
  faDownload,
  faRoute,
  faStopwatch,
  faRulerTriangle,
  faChevronRight,
  faFileImport,
  faLocationPlus,
  faCalendarLinesPen,
  faInfo,
  faTrashCan,
  faTint,
  faSnowflake,
  faBookmark,
  faCircle,
  faCalendarCircleExclamation,
  faMapLocationDot,
  faUserPlus,
  faUserLock,
  faUserCheck,
  faChevronDown,
  faCaravan,
  faCircleInfo,
  faFilePdf,
  faToolbox,
  faMagnifyingGlass,
  faHouseTree,
  faPersonHiking,
  faPhone,
  faEnvelope,
  faDiamondTurnRight,
  faCreditCard,
  faLock,
  faMessageLines,
  faListCheck,
  faArrowLeft,
  faSquarePlus,
  faPersonCarryBox,
  faAlarmClock,
  faPencil,
  faCircleNotch,
  faClone,
  faEllipsisVertical,
  faHouse,
  faArrowRight,
  faArrowsRotate,
  faPlay,
  faGears,
  faBold,
  faItalic,
  faH1,
  faH2,
  faH3,
  faListOl,
  faListUl,
  faLocationCrosshairs,
  faChevronUp,
  faUpload,
  faPaperPlaneTop,
  faCommentPlus,
  faFileImport,
  faFileExport,
  faLocationCheck,
  faEnvelopeCircleCheck,
  faBell,
  faBellPlus,
  faBellSlash,
  faUp,
  faDown,
  faEnvelopeOpen,
  faStar,
  faWandSparkles,
  faCloudBoltSun,
  faBookOpenCover,
  faMagnifyingGlassLocation,
  faClipboardListCheck,
  faComments,
  faCompass,
  faWater,
  faEye,
  faFileMagnifyingGlass,
  faMountainCity,
  faBenchTree,
  faTrees,
  faChartNetwork,
  faGarage,
  faGear,
  faIndustry,
  faAddressBook,
  faBlog,
  faSort,
  faArrowsSpin,
  faList,
  faGrip,
  faCampground,
  faCaretLeft,
  faCaretRight,
  faImage,
  faPhotoFilm,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faChevronLeft,
  faCrosshairs,
  faFileContract,
  faShieldKeyhole,
  faPersonCircleMinus,
);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("FontAwesomeIcon", FontAwesomeIcon);
});
