import {
  applicationIcons,
  check,
  directionalIcons,
  inputIcons,
} from "@formkit/icons";
import {
  autocomplete,
  createProPlugin,
  datepicker,
  dropdown,
  mask,
  toggle,
} from "@formkit/pro";
// @ts-ignore
import { createThemePlugin } from "@formkit/themes";
import { defineFormKitConfig } from "@formkit/vue";
import { rootClasses } from "./formkit.theme";

const pro = createProPlugin("fk-b6d3d1e26a", {
  dropdown,
  toggle,
  datepicker,
  autocomplete,
  mask,
});

export default defineFormKitConfig({
  plugins: [
    pro,
    createThemePlugin(undefined, {
      ...applicationIcons,
      ...directionalIcons,
      ...inputIcons,
      checkboxDecorator: check,
    }),
  ],
  config: {
    rootClasses,
  },
});
